import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image, Linking, Platform, View } from "react-native";
import CpAdSlot, { AdPlacement } from "components/common/CpAdSlot";
import CpImdLogo from "components/common/CpImdLogo";
import PrActionButton from "components/primitives/PrActionButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useActiveNavigationKey from "hooks/useActiveNavigationKey";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { ScreenSizes } from "services/Constants";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import { logoSize } from "services/Theme/common";
import translations from "translations";
const localStyles = {
  backToSessionButton: css`
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;
  `,
  backToSessionText: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  learnMoreButton: css`
    ${styles.navHeaderStyles.callToActionButton};
    align-items: center;
    justify-content: center;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-right: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  learnMoreText: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  poweredByContainer: css`
    ${styles.row};
    margin-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  poweredByText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
    margin-right: ${({
    theme
  }) => theme.spacing.half}px;
  `
};

/**
 * The navigation header at the top of each screen when viewing a PatientSession
 */
const CpPatientSessionNavHeader = ({
  route
}) => {
  const {
    brandColors,
    iconSizes,
    name,
    textColors
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const {
    appConfig
  } = useImdSession();
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    patientSessionData: {
      viewSessionData,
      viewSessionParams
    }
  } = usePatientSessionContext();
  const navigation = useNavigation();
  const isRootRoute = route.name === Routes.view_session;

  // Navigators
  const navigateToPublicApp = useCallback(() => {
    const url = "https://www.imdhealth.app/";
    Linking.openURL(url);
    // Do we want to track this event?  If so, what kind of event is it?
  }, []);
  const handleBackToSession = useCallback(() => {
    if (viewSessionParams) {
      navigation.navigate(Routes.view_session, {
        isCcRecipient: viewSessionParams.isCcRecipient.toString(),
        patientId: viewSessionParams.patientId,
        patientSessionId: viewSessionParams.patientSessionId
      });
    } else {
      Log.error("CpPatientSessionNavHeader - no params found to navigate back to the session. This should not happen since we had to get here somehow");
    }
  }, [navigation, viewSessionParams]);
  const logo = useMemo(() => {
    if (viewSessionData?.organizationLogo) {
      const {
        organizationLogo,
        organizationName
      } = viewSessionData;
      return <>
          <_StyledImage accessibilityLabel={formatMessage(translations.accessibility.label.organizationLogo, {
          organizationName
        })}
        /* TODO: Can the actual dimensions be determined somehow? This is a guesstimate of decent logo width, but each logo is different */ resizeMode="contain" source={{
          uri: organizationLogo
        }} $_css={iconSizes.large} $_css2={iconSizes.large * 1.5} />
          <_StyledView $_css3={localStyles.poweredByContainer}>
            <_StyledPrText children={translations.labels.poweredBy} $_css4={localStyles.poweredByText} />
            <CpImdLogo accessibilityLabel={formatMessage(translations.accessibility.label.imd)} fill={name === "dark" ? textColors.default : undefined} height={iconSizes.xxsmall} />
          </_StyledView>
        </>;
    } else {
      return <CpImdLogo accessibilityLabel={formatMessage(translations.accessibility.label.imdLogo, {
        region: ""
      })} accessibilityRole="image" fill={name === "dark" ? textColors.default : undefined} height={logoSize} />;
    }
  }, [formatMessage, iconSizes, name, textColors, viewSessionData]);
  const viewPatientSessionAdDesktop = useMemo(() => {
    return appConfig?.appAdvertisement?.adSpots?.find(ad => ad.placement === AdPlacement.ViewPatientSession && ad.screenWidthIs.includes(ScreenSizes.IsWideScreen));
  }, [appConfig?.appAdvertisement?.adSpots]);
  // Memoize to ensure ads show/hide if screen size changes
  const shouldDisplayAd = useMemo(() => !appConfig?.appAdvertisement?.hideAds && isRootRoute && isWideScreen, [appConfig?.appAdvertisement?.hideAds, isRootRoute, isWideScreen]);
  const renderEmptySearchbarSpace = useMemo(() => {
    if (viewPatientSessionAdDesktop && shouldDisplayAd) {
      return <CpAdSlot labelPlacement="vertical" slot={viewPatientSessionAdDesktop.slot} />;
    } else {
      return <_StyledView2 $_css5={styles.flexSingle} />;
    }
  }, [shouldDisplayAd, viewPatientSessionAdDesktop]);
  const navKey = useActiveNavigationKey();

  // This if statement prevents multiple copies of headers from being rendered in float mode when you navigate between pages on web
  if (Platform.OS === "web" && navKey !== route.key) {
    return null;
  } else {
    return <_StyledView3 $_css6={styles.navHeaderStyles.container}>
        <_StyledView4 $_css7={styles.navHeaderStyles.row1Container}>
          {/* Org. Logo || Back to session */}
          {isRootRoute ? <_StyledView5 $_css8={styles.navHeaderStyles.logo}>{logo}</_StyledView5> : <_StyledPrPressable onPress={handleBackToSession} testID="CpPatientSessionNavHeader-backToSessionButton" $_css9={localStyles.backToSessionButton}>
              <PrIcon fill={brandColors.primary} icon={IconName.back} size={iconSizes.medium} />
              <_StyledPrText2 $_css10={localStyles.backToSessionText}>
                <FormattedMessage id="nbSAJR" defaultMessage="Back to session" />
              </_StyledPrText2>
            </_StyledPrPressable>}
          {!isNarrowScreen ? renderEmptySearchbarSpace : null}

          <_StyledView6 $_css11={styles.navHeaderStyles.row1ButtonContainer}>
            {/* Learn More @ IMD Button */}
            {isRootRoute && <_StyledPrPressable2 accessibilityHint={formatMessage(translations.accessibility.hint.openInNewTab, {
            baseHint: formatMessage(translations.accessibility.hint.navHeaderImdButton)
          })} accessibilityRole="link" onPress={navigateToPublicApp} testID="CpPatientSessionNavHeader-publicAppLink" $_css12={localStyles.learnMoreButton}>
                <CpImdLogo accessibilityLabel={formatMessage(translations.accessibility.label.imdLogo, {
              region: ""
            })} accessibilityRole="image" fill={name === "dark" ? brandColors.primary : undefined} height={iconSizes.small} />
                <_StyledPrText3 children={translations.buttons.learnMore} $_css13={localStyles.learnMoreText} />
              </_StyledPrPressable2>}

            {/* Dark mode & Language picker */}
            <PrActionButton actionButton="MODE" type={ActionButtonUserType.NavHeader} />
            <PrActionButton actionButton="PICK_LANGUAGE_AND_REGION" type={ActionButtonUserType.NavHeader} />
          </_StyledView6>
        </_StyledView4>
      </_StyledView3>;
  }
};
export default CpPatientSessionNavHeader;
var _StyledImage = _styled(Image).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledImage",
  componentId: "sc-11vkh9o-0"
})(["height:", "px;min-width:", "px;"], p => p.$_css, p => p.$_css2);
var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledView",
  componentId: "sc-11vkh9o-1"
})(["", ""], p => p.$_css3);
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledPrText",
  componentId: "sc-11vkh9o-2"
})(["", ""], p => p.$_css4);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledView2",
  componentId: "sc-11vkh9o-3"
})(["", ""], p => p.$_css5);
var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledView3",
  componentId: "sc-11vkh9o-4"
})(["", ""], p => p.$_css6);
var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledView4",
  componentId: "sc-11vkh9o-5"
})(["", ""], p => p.$_css7);
var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledView5",
  componentId: "sc-11vkh9o-6"
})(["", ""], p => p.$_css8);
var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledPrPressable",
  componentId: "sc-11vkh9o-7"
})(["", ""], p => p.$_css9);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledPrText2",
  componentId: "sc-11vkh9o-8"
})(["", ""], p => p.$_css10);
var _StyledView6 = _styled(View).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledView6",
  componentId: "sc-11vkh9o-9"
})(["", ""], p => p.$_css11);
var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledPrPressable2",
  componentId: "sc-11vkh9o-10"
})(["", ""], p => p.$_css12);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionNavHeader___StyledPrText3",
  componentId: "sc-11vkh9o-11"
})(["", ""], p => p.$_css13);