import _styled from "styled-components";
import React, { useMemo } from "react";
import CpOfflineIndicator from "components/common/CpOfflineIndicator";
import CtAppInitializers from "components/containers/CtAppInitializers";
import CtErrorBoundary from "components/containers/CtErrorBoundary";
import CtNavigation from "components/containers/CtNavigation";
import CtPatientSession from "components/containers/CtPatientSession";
import NvPublicRoot from "navigators/NvPublicRoot";
import Config from "services/Config";
import Log from "services/Log";
import { styles } from "services/Theme";
Log.info("Starting public app", Config);

/**
 * This container component wraps the public app with the providers it needs to operate in a web environment.
 */
const PublicApp = () => {
  const appPrefixes = useMemo(() => {
    return ["https://www.imdhealth.app", "imdhealth://", "http://localhost:9001"];
  }, []);
  return <CtAppInitializers>
      <_StyledCtErrorBoundary $_css={styles.paddingDouble}>
        <CtNavigation prefixes={appPrefixes}>
          <CtPatientSession>
            <CpOfflineIndicator />
            <NvPublicRoot />
          </CtPatientSession>
        </CtNavigation>
      </_StyledCtErrorBoundary>
    </CtAppInitializers>;
};
export default PublicApp;
var _StyledCtErrorBoundary = _styled(CtErrorBoundary).withConfig({
  displayName: "PublicAppweb___StyledCtErrorBoundary",
  componentId: "sc-hpl1ii-0"
})(["", ""], p => p.$_css);