var _QlViewPatientSessionQuery;
import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
/**
 * Used to fetch a PatientSession and its PatientSessionResourceNodes children
 */
export const QlViewPatientSessionQueryRequest = _QlViewPatientSessionQuery !== void 0 ? _QlViewPatientSessionQuery : _QlViewPatientSessionQuery = require("./__generated__/QlViewPatientSessionQuery.graphql");
const QlViewPatientSession = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlViewPatientSessionQueryRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};
export default QlViewPatientSession;