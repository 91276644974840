import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpGridItem from "components/home/tabs/CpGridItem";
import CpTopicTreeSection from "components/home/tabs/CpTopicTreeSection";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrText from "components/primitives/PrText";
import isUrlString from "helpers/isUrlString";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { getNameVariant, getRootNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
const localStyles = {
  infoText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  sectionHeading: css`
    ${styles.textHeader2};
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-bottom: 0px;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const localTranslations = defineMessages({
  practitionerName: {
    id: "kWFdQW",
    defaultMessage: "Healthcare Practitioner"
  },
  viewPsp: {
    id: "PMoi86",
    defaultMessage: "My Treatment Journey"
  }
});
/**
 * Renders a grid of PatientSessionResourceNodes grouped by topic
 */
const CpViewSessionSelect = ({
  onUpdateHeader,
  pageHeader,
  patientSession
}) => {
  const navigation = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isViewingSession,
      viewSessionData,
      viewSessionParams
    }
  } = usePatientSessionContext();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const practitionerName = patientSession?.practitionerName ?? formatMessage(localTranslations.practitionerName);
  useEffect(() => {
    if (patientSession && !viewSessionData) {
      dispatchPatientSession({
        payload: {
          viewSessionData: {
            isPsp: patientSession?.isPsp,
            organizationLogo: patientSession?.organizationLogo,
            organizationName: patientSession.organizationName
          }
        },
        type: PatientSessionActions.SetViewingData
      });
    }
  }, [dispatchPatientSession, formatMessage, isViewingSession, patientSession, viewSessionData, viewSessionParams]);
  useEffect(() => {
    if (patientSession?.isPsp && pageHeader !== localTranslations.viewPsp) {
      onUpdateHeader(localTranslations.viewPsp);
    }
  }, [formatMessage, onUpdateHeader, pageHeader, patientSession?.isPsp]);

  // Navigation callback for topics or resourceNodes
  // ResourceNodes will have a 'locked' view to ensure the TOC, breadcrumbs, and shortcuts are not accessible
  const handleNavigate = useCallback((rootRevisionId, selectedRevisionId) => {
    if (rootRevisionId === selectedRevisionId) {
      dispatchPatientSession({
        payload: {
          isLockedResource: false
        },
        type: PatientSessionActions.SetViewingLock
      });
      return navigation.navigate(Routes.topic, {
        rootRevisionId
      });
    } else {
      dispatchPatientSession({
        payload: {
          isLockedResource: true
        },
        type: PatientSessionActions.SetViewingLock
      });
      return navigation.navigate(Routes.topic, {
        rootRevisionId,
        selectedRevisionId
      });
    }
  }, [dispatchPatientSession, navigation]);

  // Reduce a patientSessions children to be grouped by each root topic for rendering
  const groupedPatientSessionResourceNodes = useMemo(() => patientSession?.children.reduce((prevValue, child) => {
    const {
      icon,
      resourceNodeRevisionId,
      rootResourceNodeRevisionId
    } = child;

    // Find the parent group
    const currentIndex = prevValue.findIndex(group => group?.rootId === rootResourceNodeRevisionId);
    const isInPrevValue = currentIndex >= 0;
    // And either modify the existing group, or create a new one for future use
    const currentGroup = isInPrevValue ? prevValue[currentIndex] : {
      nodes: [],
      rootId: rootResourceNodeRevisionId,
      rootName: getRootNameVariant(child)
      // Typescript yells if I don't do this
    };
    const label = getNameVariant(child, ["short", "default"]);
    // Since GridItem wants a separate icon or image prop, we need to confirm them here
    const image = icon && isUrlString(icon) ? icon : undefined;
    const localIcon = image ? undefined : icon;
    currentGroup.nodes.push(<CpGridItem
    // Since longer labels may be truncated we'll ensure the accessibility label has the full label available to it
    accessibilityLabel={label} color="primary" icon={localIcon} image={image} key={resourceNodeRevisionId} link={{
      link_type: "topic",
      value: ""
    }} onPress={() => handleNavigate(rootResourceNodeRevisionId, resourceNodeRevisionId)} testID={`CpViewSessionSelect-${rootResourceNodeRevisionId}-${resourceNodeRevisionId}`} title={label} />);
    if (child.annotation) {
      dispatchPatientSession({
        payload: {
          resourceNodeRevisionId,
          s3Key: child.annotation,
          selected: true,
          url: child.annotation
        },
        type: PatientSessionActions.AddAnnotation
      });
    }

    // Now either overwrite the existing group data or add it
    if (isInPrevValue) prevValue[currentIndex] = currentGroup;else prevValue.push(currentGroup);
    return prevValue;
  }, []), [dispatchPatientSession, handleNavigate, patientSession?.children]);

  // If it's a PSP, we'll want to render the PSP header
  if (patientSession?.isPsp && patientSession.children?.[0]?.topicNodeRevisionId) {
    return <CpTopicTreeSection topicTreeId={patientSession.children[0].topicNodeRevisionId} />;
  }
  return <View>
      <_StyledPrText $_css={localStyles.infoText}>
        {patientSession?.fromPackage ? <FormattedMessage id="I9/P43" defaultMessage="Below you will find the topics and resources included in your Patient Information Package from {doctorOrg}." values={{
        doctorOrg: <_StyledPrText2 children={patientSession?.organizationName} $_css2={styles.boldText} />
      }} /> : <FormattedMessage id="/LY9Y7" defaultMessage="Below you will find the topics and resources compiled during your Patient Session {practitioner}from {doctorOrg}." values={{
        practitioner: practitionerName ? <PrText>
                  <FormattedMessage id="9v0rmC" defaultMessage="with {practitionerName}{blankSpace}" values={{
            blankSpace: " ",
            // Intl strips preceding and trailing blank spaces from translations, so need to force one in here.
            practitionerName: <_StyledPrText3 children={practitionerName} $_css3={styles.boldText} />
          }} />
                </PrText> : null,
        doctorOrg: <_StyledPrText4 children={patientSession?.organizationName} $_css4={styles.boldText} />
      }} />}
      </_StyledPrText>
      {groupedPatientSessionResourceNodes?.map(group => <_StyledView key={group.rootId} $_css5={styles.paddingVerticalDouble}>
          <_StyledPrText5 accessibilityRole="header" aria-level="2" children={group.rootName} $_css6={localStyles.sectionHeading} />
          <_StyledView2 $_css7={isNarrowScreen ? styles.fullWidth : styles.rowAndWrap}>{group.nodes}</_StyledView2>
        </_StyledView>)}
    </View>;
};
export default CpViewSessionSelect;
var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpViewSessionSelect___StyledPrText",
  componentId: "sc-l88p5d-0"
})(["", ""], p => p.$_css);
var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpViewSessionSelect___StyledPrText2",
  componentId: "sc-l88p5d-1"
})(["", ""], p => p.$_css2);
var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpViewSessionSelect___StyledPrText3",
  componentId: "sc-l88p5d-2"
})(["", ""], p => p.$_css3);
var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpViewSessionSelect___StyledPrText4",
  componentId: "sc-l88p5d-3"
})(["", ""], p => p.$_css4);
var _StyledView = _styled(View).withConfig({
  displayName: "CpViewSessionSelect___StyledView",
  componentId: "sc-l88p5d-4"
})(["", ""], p => p.$_css5);
var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpViewSessionSelect___StyledPrText5",
  componentId: "sc-l88p5d-5"
})(["", ""], p => p.$_css6);
var _StyledView2 = _styled(View).withConfig({
  displayName: "CpViewSessionSelect___StyledView2",
  componentId: "sc-l88p5d-6"
})(["", ""], p => p.$_css7);